<template>
  <section>
    <h2>Diensten</h2>
    <h3>ZZP-orthopedagoog</h3>
    <p>Zowel ouders, verzorgers als scholen kunnen een beroep op mij doen. Ouders
     kunnen mij voor de particuliere zorg benaderen. Scholen kunnen mij inhuren
   voor de invulling van een zorgarrangement of via andere onderwijsgelden.</p>
    <h3>Samenwerking</h3>
    <p>In mijn vak heb ik vaak intercollegiaal consult en onderhoud ik contacten
     met jeugdzorghulpverleners die werkzaam zijn op verschillende terreinen.
      Daarnaast ben ik als freelance-orthopedagoog verbonden aan verschillende
       vrijgevestigde praktijken, zoals OC Leidsche Rijn en RondomLeren.
        Via deze praktijken is het mogelijk om in aanmerking te komen voor de vergoede
         zorg. Wanneer u dit wilt, kunt u het beste even contact met mij opnemen om de
     mogelijkheden te bespreken.</p>
  </section>
</template>

<style scoped lang="scss">
section {
  text-align: left;
  padding: 10px;
}
</style>
